import data from '../data/data-v3.json';


const isControllerIdValid = (id) => {
    return data.brands.some(brand =>
        brand.controllers.some(controller => controller.controllerid === id)
    );
};

const getControllerDetailsById = (id) => {
    for (const brand of data.brands) {
        const controller = brand.controllers.find(controller => controller.controllerid === id);
        if (controller) {
            return {
                title: controller.title,
                description: controller.description,
                brand: brand.brandname
            };
        }
    }
    return null;
};

// const findDescription = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         return item ? item.description : undefined;
//     }).filter(Boolean).join(', ');
// };

// const findDescription = (ids, type) => {
//     if (!data[type]) {
//         console.error(`findDescription error: No data found for type ${type}`);
//         return ids.map(() => 'No description available');
//     }
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         return item ? item.description : 'Description not found';
//     }).filter(Boolean).join(', ');
// };

const findDescription = (ids, type) => {
    if (!data[type]) {
        console.error(`findDescription error: No data found for type ${type}`);
        return ids.map(() => 'No description available');
    }
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        return item ? item.description : 'Description not found';
    });
};


const findSwitchesDetails = (ids, type) => {
    const details = ids.map(id => {
        const item = data[type].find(item => item.switchid === parseInt(id));
        if (!item) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }

        const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
        const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

        return {
            description: item.description,
            notes: item.notes,
            styles: item.styles,
            link: link,
            seller: seller
        };
    });

    details.sort((a, b) => a.description.localeCompare(b.description));
    return details;
};

const findAccessoriesDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item.accessoriesid === parseInt(id));

        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findKeycapsDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findAdaptersDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

// export const filterBrands = (selectedIds, filterKey) => {
//     return data.brands
//         .filter(brand => selectedIds.includes(brand[filterKey]))  // Directly filtering brands
//         .map(brand => {
//             const enhancedControllers = brand.controllers.map(controller => ({
//                 ...controller,
//                 controllerstyleDesc: findDescription(controller.controllerstyles, 'controllerstyle'),
//                 sizesDesc: findDescription(controller.sizes, 'size'),
//                 playLocationsDesc: findDescription(controller.playlocations, 'playlocation'),
//                 keycapsDesc: findDescription(controller.keycaps, 'keycaps'),
//                 accessoriesDesc: findDescription(controller.accessories, 'accessories'),
//                 switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
//                 adaptersDesc: findDescription(controller.adapters, 'adapters')
//             }));

//             return {
//                 brandData: brand,
//                 filteredControllers: enhancedControllers
//             };
//         }).filter(brand => brand.filteredControllers.length > 0);
// };

// export const filterBrands = (selectedIds, filterKey) => {
//     return data.brands
//         .filter(brand => selectedIds.includes(brand[filterKey]))  // Ensure this logic is intended and correct
//         .map(brand => {
//             const enhancedControllers = brand.controllers.map(controller => ({
//                 ...controller,
//                 controllerstyleDesc: findDescription(controller.controllerstyles, 'controllerstyles'), // Adjusted to plural
//                 sizesDesc: findDescription(controller.sizes, 'sizes'),  // Adjusted to plural
//                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),  // Adjusted to plural
//                 keycapsDesc: findDescription(controller.keycaps, 'keycaps'), // Correct if plural
//                 accessoriesDesc: findDescription(controller.accessories, 'accessories'), // Correct if plural
//                 switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'), // Correct if plural
//                 adaptersDesc: findDescription(controller.adapters, 'adapters') // Correct if plural
//             }));

//             return {
//                 brandData: brand,
//                 filteredControllers: enhancedControllers
//             };
//         }).filter(brand => brand.filteredControllers.length > 0);
// };






// export const filterBrands = (selectedIds, filterKey) => {

//     const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
//         isControllerIdValid(similarId)
//     );

//     const similarControllersDesc = validSimilarControllers.map(similarId => 
//         getControllerDetailsById(similarId)
//     ).filter(details => details !== null);  // Filter out null values if any

//     return data.brands
//         .filter(brand => selectedIds.includes(brand[filterKey]))  // Ensure this check is correct
//         .map(brand => {
//             const enhancedControllers = brand.controllers.map(controller => ({
//                 ...controller,
//                 // controllerstyleDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
//                 // sizesDesc: findDescription(controller.sizes, 'sizes'),
//                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
//                 // keycapsDesc: findDescription(controller.keycaps, 'keycaps'),
//                 // accessoriesDesc: findDescription(controller.accessories, 'accessories'),
//                 // switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
//                 // adaptersDesc: findDescription(controller.adapters, 'adapters'),

//                 controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
//                 sizesDesc: findDescription(controller.sizes, 'sizes'),
//                         // playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
//                 keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
//                 accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),                
//                 //         switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
//                 switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
//                 switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
//                 adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
//                 similarControllersDesc: similarControllersDesc


//             }));
//             return {
//                 brandData: brand,
//                 filteredControllers: enhancedControllers
//             };
//         }).filter(brand => brand.filteredControllers.length > 0);
// };




export const filterBrands = (selectedIds, filterKey) => {
    return data.brands
        .filter(brand => selectedIds.includes(brand[filterKey]))
        .map(brand => {
            const enhancedControllers = brand.controllers.map(controller => {
                const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
                    isControllerIdValid(similarId)
                );

                const similarControllersDesc = validSimilarControllers.map(similarId => 
                    getControllerDetailsById(similarId)
                ).filter(details => details !== null);

                return {
                    ...controller,
                    controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
                    sizesDesc: findDescription(controller.sizes, 'sizes'),
                    playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
                    keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
                    accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
                    switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
                    switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
                    adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
                    similarControllersDesc: similarControllersDesc
                };
            });
            return {
                brandData: brand,
                filteredControllers: enhancedControllers
            };
        }).filter(brand => brand.filteredControllers.length > 0);
};
