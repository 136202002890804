import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppPage from './pages/AppPage';
import ControllerStylePage from './pages/ControllerStylePage';
import SwitchSelectionPage from './pages/SwitchSelectionPage';
import AdapterSelectionPage from './pages/AdapterSelectionPage';
import SizeSelectionPage from './pages/SizeSelectionPage';
import PlayLocationSelectionPage from './pages/PlayLocationSelectionPage';
import AccessoriesSelectionPage from './pages/AccessoriesSelectionPage';
import ButtonCountSelectionPage from './pages/ButtonCountSelectionPage';
import BrandSelectionPage from './pages/BrandSelectionPage';
// import AddControllerForm from './components/AddControllerForm';
import AddControllerPage from "./pages/AddControllerPage";
import SimilarControllerSelectionPage from './pages/SimilarControllerSelectionPage';
import SimilarControllerSelectionPage2 from './pages/SimilarControllerSelectionPage2';

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<AppPage />} />
        <Route path="/controller-style-selection" element={<ControllerStylePage />} />
        <Route path="/switch-selection" element={<SwitchSelectionPage />} />
        <Route path="/adapter-selection" element={<AdapterSelectionPage />} />
        <Route path="/size-selection" element={<SizeSelectionPage />} />
        <Route path="/playlocation-selection" element={<PlayLocationSelectionPage />} />
        <Route path="/accessories-selection" element={<AccessoriesSelectionPage />} />
        <Route path="/button-count-selection" element={<ButtonCountSelectionPage />} />
        <Route path="/brand-selection" element={<BrandSelectionPage />} />
        <Route path="/similar-controller" element={<SimilarControllerSelectionPage />} />
        <Route path="/similar-controller-2" element={<SimilarControllerSelectionPage2 />} />

        {/* <Route path="/add-controller" element={<AddControllerForm />} /> */}
        <Route path="/add-controller" element={<AddControllerPage />} />
      </Routes>
    </Router>
  );
}

export default App;
