import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const AdapterSelection = () => {
    const [adapters, setAdapters] = useState([]);
    const [selectedAdapters, setSelectedAdapters] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (!data.adapters || data.adapters.length === 0) {
            console.error("Adapters data is empty or not loaded");
        } else {
            setAdapters(data.adapters);
        }
    }, []);

    const handleAdapterChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                // selected.push(parseInt(options[i].value, 10));
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedAdapters(selected);
    };

    const handleFilter = () => {
        if (selectedAdapters.length === 0) return;
        const filteredResults = filterControllers(selectedAdapters, 'adapters');
        setResults(filteredResults);
    };

    return (
        <div className="search-n-results">
            <h4 className="title">Adapter Selection</h4>

            <div className="filter-container">
                <label htmlFor="adapter-filter">Select Adapters:</label>
                <br />
                <select
                    id="adapter-filter"
                    multiple 
                    value={selectedAdapters}
                    onChange={handleAdapterChange}
                    className="multi-select"
                >
                    {adapters.map((adapter) => (
                        <option key={adapter.adapterid} value={adapter.adapterid}>
                            {adapter.name}
                        </option>
                    ))}
                </select>
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Brands Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />
                    ))
                ) : (
                    <p className="no-results">No results found. Please select adapter.</p>
                )}
            </div>

            <hr />

            <div>
                <h4>All Available Adapters</h4>
                <ul>
                    {adapters.map((adapter) => (
                        <li key={adapter.adapterid}>
                            <strong>{adapter.name}</strong>: {adapter.description}
                            {adapter.links && adapter.links.length > 0 ? (
                                adapter.links.map((link, index) => (
                                    <p key={index}>
                                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                                            Buy on {link.seller}
                                        </a>
                                    </p>
                                ))
                            ) : (
                                <p>No links available</p>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdapterSelection;



























// import React, { useState, useEffect } from 'react';
// import { filterControllers } from '../utils/filterControllers';
// import ResultCard from './ResultCard';
// import data from '../data/data-v3.json';

// const AdapterSelection = () => {
//     const [adapters, setAdapters] = useState([]);
//     const [selectedAdapters, setSelectedAdapters] = useState([]);
//     const [results, setResults] = useState([]);

//     useEffect(() => {
//         setAdapters(data.adapters);
//     }, []);

//     const handleAdapterChange = (e) => {
//         const { options } = e.target;
//         const selected = [];
//         for (let i = 0; i < options.length; i++) {
//             if (options[i].selected) {
//                 selected.push(parseInt(options[i].value));
//             }
//         }
//         setSelectedAdapters(selected);
//     };

//     const handleFilter = () => {
//         if (selectedAdapters.length === 0) return;
//         const filteredResults = filterControllers(selectedAdapters, 'adapters');
//         setResults(filteredResults);
//     };

//     return (
//         <div className="search-n-results">
//             <h4 className="title">Adapter Selection</h4>

//             <div className="filter-container">
//                 <label htmlFor="adapter-filter">Select Adapters:</label>
//                 <br />
//                 <select
//                     id="adapter-filter"
//                     multiple
//                     value={selectedAdapters}
//                     onChange={handleAdapterChange}
//                     style={{ width: '50%' }}
//                     className="multi-select"
//                 >
//                     {adapters.map((adapter) => (
//                         <option key={adapter.adaptersid} value={adapter.adaptersid}>
//                             {adapter.name}
//                         </option>
//                     ))}
//                 </select>
//                 <br />
//                 <button onClick={handleFilter} className="filter-button">Filter</button>
//             </div>

//             <div className="results-container">
//                 <h2>{results.length} Brands Found:</h2>
//                 {results.length > 0 ? (
//                     results.map((result, index) => (
//                         <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
//                     ))
//                 ) : (
//                     <p className="no-results">No results found. Please select adapter.</p>
//                 )}
//             </div>

//             <hr />

//             <div>
//                 <h4>All Available Adapters</h4>
//                 <ul>
//                     {adapters.map((adapter) => (
//                         <li key={adapter.adaptersid}>
//                             <strong>{adapter.name}</strong>: {adapter.description}
//                             {adapter.links && adapter.links.length > 0 ? (
//                                 adapter.links.map((link, index) => (
//                                     <p key={index}>
//                                         <a href={link.link} target="_blank" rel="noopener noreferrer">
//                                             Buy on {link.seller}
//                                         </a>
//                                     </p>
//                                 ))
//                             ) : (
//                                 <p>No links available</p>
//                             )}
//                         </li>
//                     ))}
//                 </ul>
//             </div>


            
//         </div>
//     );
// };

// export default AdapterSelection;
