import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const AccessoriesSelection = () => {
  const [accessories, setAccessories] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setAccessories(data.accessories);
  }, []);

    const handleAccessoryChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedAccessories(selected);
    };

    const handleFilter = () => {
        if (selectedAccessories.length === 0) return;
        const filteredResults = filterControllers(selectedAccessories, 'accessories');
        setResults(filteredResults);
    };

  return (
    <div className="search-n-results">
      <h4 className="title">Accessories Selection</h4>

      <div className="filter-container">
        <label htmlFor="accessory-filter">Select Accessories:</label>
        <select
          id="accessory-filter"
          multiple
          value={selectedAccessories}
          onChange={handleAccessoryChange}
          className="multi-select"
        >
          {accessories.map((accessory) => (
            <option key={accessory.accessoriesid} value={accessory.accessoriesid}>
              {accessory.name}
            </option>
          ))}
        </select>
        <button onClick={handleFilter} className="filter-button">Filter</button>
        </div>

        <div className="results-container">
            <h2>{results.length} Brands Found:</h2>
            {results.length > 0 ? (
                results.map((result, index) => (
                    <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                ))
            ) : (
                <p className="no-results">No results found. Please select accessories.</p>
            )}
        </div>


<hr />
<hr />
<hr />
<hr />

        <div>
            <h4>Accessories Options</h4>
            <ul>
                {accessories.map((accessory, index) => (
                    <li key={index}>
                        <strong>{accessory.name}</strong>: {accessory.description}
                        {accessory.links && accessory.links.length > 0 ? (
                            accessory.links.map((link, linkIndex) => (
                                <p key={linkIndex}>
                                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                                        Buy on {link.seller}
                                    </a>
                                </p>
                            ))
                        ) : (
                            <p>No links available</p>
                        )}
                    </li>
                ))}
            </ul>
        </div>

            
    </div>
  );
};

export default AccessoriesSelection;
