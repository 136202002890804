import React from 'react';
import AdapterSelection from '../components/AdapterSelection';
import '../pagestyles/AdapterSelectionPage.scss';
import Footer from '../components/Footer';

const AdapterSelectionPage = () => {
    return (
        <div className="selection-page">
            <AdapterSelection />
            <Footer />
        </div>
    );
};

export default AdapterSelectionPage;
