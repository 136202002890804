import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const SwitchSelection = () => {
  const [switches, setSwitches] = useState([]);
  const [selectedSwitches, setSelectedSwitches] = useState([]);
  const [stockResults, setStockResults] = useState([]);
  const [upgradeResults, setUpgradeResults] = useState([]);

  useEffect(() => {
    setSwitches(data.switches);
  }, []);

  const handleSwitchChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
            selected.push(parseInt(options[i].value));
        }
    }
    setSelectedSwitches(selected);
};

  const handleFilter = () => {
    if (selectedSwitches.length === 0) return;
    const stockResults = filterControllers(selectedSwitches, 'stockswitches');
    const upgradeResults = filterControllers(selectedSwitches, 'switchesupgrades');
    setStockResults(stockResults);
    setUpgradeResults(upgradeResults);
  };

  return (
    <div className="search-n-results">
      <h4 className="title">Switch Selection</h4>

      <div className="filter-container">
        <label htmlFor="switch-filter">Select Switches:</label>
        <select 
            id="switch-filter" 
            multiple 
            value={selectedSwitches} 
            onChange={handleSwitchChange} 
            className="multi-select"
        >
          {switches.map((switchItem) => (
            <option key={switchItem.switchid} value={switchItem.switchid}>
              {switchItem.description}
            </option>
          ))}
        </select>
        <button onClick={handleFilter} className="filter-button">Filter</button>
      </div>

        <div className="results-container">
            <div className="results-container">
                {/* <h2>{stockResults.length} Brands Found:</h2> */}
                {/* <h2>{stockResults.length} Brands with these Stock Switches Found:</h2> */}
                <h2>
                  Stock Switches:
                </h2>
                <h4>
                  {stockResults.length} Brands Found:
                </h4>
                {stockResults.length > 0 ? (
                    stockResults.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                    ))
                ) : (
                    <p className="no-results">No results found. Please select switch.</p>
                )}
            </div>
        </div>

        <br />

        <div className="results-container">
            <div className="results-container">
            {/* <h2>{upgradeResults.length} Brands with these compatible Switch Upgrades Found:</h2> */}
            <h2>
              Upgradeable to Selected Switches:
            </h2>
            <h4>
              {upgradeResults.length} Brands Found:
            </h4>
            {upgradeResults.length > 0 ? (
                upgradeResults.map((result, index) => (
                    <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                ))
            ) : (
                <p className="no-results">No results found. Please select switch.</p>
            )}
            </div>
        </div>

<br />
<hr />
<hr />
<hr />
<br />

        <div>
            <h2>All Switch Options</h2>
            <ul>
            {switches.map((switchItem, index) => (
                <li key={index}>
                <strong>{index + 1}. {switchItem.description}</strong>
                <p>{switchItem.notes}</p>
                <ul>
                    {switchItem.styles.map((style, styleIndex) => (
                    <li key={styleIndex}>{style}</li>
                    ))}
                </ul>
                {switchItem.links.length > 0 ? (
                    <div>
                    {switchItem.links.map((link, linkIndex) => (
                        <p key={linkIndex}>
                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                            Buy on {link.seller}
                        </a>
                        </p>
                    ))}
                    </div>
                ) : (
                    <p>No links available</p>
                )}
                </li>
            ))}
            </ul>
        </div>


    </div>
  );
};

export default SwitchSelection;
