import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const PlayLocationSelection = () => {
  const [playLocations, setPlayLocations] = useState([]);
  const [selectedPlayLocations, setSelectedPlayLocations] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
      setPlayLocations(data.playlocations);
  }, []);

  const handlePlayLocationChange = (e) => {
      const { options } = e.target;
      const selected = [];
      for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
              selected.push(parseInt(options[i].value));
          }
      }
      setSelectedPlayLocations(selected);
  };

  const handleFilter = () => {
      if (selectedPlayLocations.length === 0) return;
      const filteredResults = filterControllers(selectedPlayLocations, 'playlocations');
      setResults(filteredResults);
  };

  return (
    <div className="search-n-results">
      <h4 className="title">Play Location Selection</h4>

      <div className="filter-container">
        <label htmlFor="playlocation-filter">Select Play Locations:</label>
        <br />
        <select
          id="playlocation-filter"
          multiple
          value={selectedPlayLocations}
          onChange={handlePlayLocationChange}
          className="multi-select"
        >
          {playLocations.map((location) => (
            <option key={location.playlocationid} value={location.playlocationid}>
              {location.name}
            </option>
          ))}
        </select>
        <br />
        <button onClick={handleFilter} className="filter-button">Filter</button>
      </div>

        <div className="results-container">
          <h2>{results.length} Brands Found:</h2>
          {results.length > 0 ? (
              results.map((result, index) => (
                  <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
              ))
          ) : (
              <p className="no-results">No results found. Please select styles.</p>
          )}
        </div>
    </div>

  );
};

export default PlayLocationSelection;
