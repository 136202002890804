import React, { useState, useEffect } from 'react';
import { filterBrands } from '../utils/filterBrands';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const BrandSelection = () => {
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setBrands(data.brands);
    }, []);

    const handleBrandChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedBrands(selected);
    };

    const handleFilter = () => {
        if (selectedBrands.length === 0) return;
        const filteredResults = filterBrands(selectedBrands, 'brandid');
        setResults(filteredResults);
    };

    return (
        <div className="search-n-results">
            <h4 className="title">Brand Selection</h4>

            <div className="filter-container">
                <label htmlFor="brand-filter">Select Brands:</label>
                <br />
                <select
                    id="brand-filter"
                    multiple
                    value={selectedBrands}
                    onChange={handleBrandChange}
                    className="multi-select"
                >
                    {brands.map((brand) => (
                        <option key={brand.brandid} value={brand.brandid}>
                            {brand.brandname}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Brands Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                    ))
                ) : (
                    <p className="no-results">No results found. Please select brand.</p>
                )}
            </div>
        </div>
    );
};

export default BrandSelection;
