import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const SizeSelection = () => {
    const [sizes, setSizes] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setSizes(data.sizes);
    }, []);

    const handleSizeChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedSizes(selected);
    };

    const handleFilter = () => {
        if (selectedSizes.length === 0) return;
        const filteredResults = filterControllers(selectedSizes, 'sizes');
        setResults(filteredResults);
    };

    return (
        <div className="search-n-results">
            <h4 className="title">Size Selection</h4>

            <div className="filter-container">
                <label htmlFor="size-filter">Select Sizes:</label>
                <br />
                <select
                    id="size-filter"
                    multiple
                    value={selectedSizes}
                    onChange={handleSizeChange}
                    style={{ width: '50%' }}
                    className="multi-select"
                >
                    {sizes.map((size) => (
                        <option key={size.sizeid} value={size.sizeid}>
                            {size.name}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Brands Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                    ))
                ) : (
                    <p className="no-results">No results found. Please select size.</p>
                )}
            </div>
        </div>
    );
};

export default SizeSelection;
