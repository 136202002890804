import React from "react";
// import React, { useState, useEffect } from "react";
import ControllerForm from "../components/AddControllerForm";
// import ControllerForm from "../components/AddControllerForm2";
import '../pagestyles/AddControllerPage.scss';

const AddControllerPage = () => {
  // const [jsonData, setJsonData] = useState(null);

  // useEffect(() => {
  //   // fetch("/data/data-v3.json")
  //   fetch("data-v3.json")
  //     .then((response) => {
  //       if (!response.ok) throw new Error("Failed to fetch JSON");
  //       return response.json();
  //     })
  //     .then(setJsonData)
  //     .catch(console.error);
  // }, []);

  const handleSave = (newControllerData) => {
    const jsonString = JSON.stringify(newControllerData, null, 2);

    navigator.clipboard
      .writeText(jsonString)
      .then(() => alert("Data copied to clipboard"))
      .catch(() => alert("Failed to copy data to clipboard"));

    console.log("Saved Data:", jsonString);
  };

  return (
    <div className="add-controller">
      <h1>Add Controller</h1>
      <ControllerForm onSave={handleSave} />
      <br />

{/* 
      <h2>Loaded JSON Data:</h2>
      {jsonData ? (
        <pre style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {JSON.stringify(jsonData, null, 2)}
        </pre>
      ) : (
        <p>Loading...</p>
      )}

*/}

    </div>
  );
};

export default AddControllerPage;
