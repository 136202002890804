import React, { useState } from 'react';

const ControllerDetails = ({ controller, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);
    
    // console.log("cD - controller", controller);

    // if (!controller || !controller.someArrayProp) {
    if (!controller) {
        return <div>No data available</div>;
    }

    return (
        <li>
            <div onClick={toggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <strong>{index + 1}. {controller.title}</strong>
                <span className="material-icons">
                    {isOpen ? 'expand_less' : 'expand_more'}
                </span>
                <i>(CLICK FOR DETAILS)</i>
            </div>
            {isOpen && (
                <div>
                    <p>{controller.description}</p>
                    <div className="links">
                        <strong>Links:</strong>
                        <ul>
                            {controller.sellers.map((seller, sellerIndex) => (
                                <li key={sellerIndex}>
                                    {seller.name} 
                                    <a href={seller.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {seller.name} */}
                                        ( Buy Now )
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div><strong>Sizes:</strong>
                        <ul>
                            {controller.sizesDesc.map((size, sizeIndex) => (
                                <li key={sizeIndex}>{size}</li>
                            ))}
                        </ul>
                    </div>
                    <div><strong>Controller Styles:</strong>
                        <ul>
                            {controller.controllerstylesDesc.map((controllerstyle, controllerstyleIndex) => (
                                <li key={controllerstyleIndex}>{controllerstyle}</li>
                            ))}
                        </ul>
                    </div>
                    <div><strong>Play Locations:</strong>
                        <ul>
                            {controller.playLocationsDesc.map((playLocation, playLocationIndex) => (
                                <li key={playLocationIndex}>{playLocation}</li>
                            ))}
                        </ul>
                    </div>

{/* 
                    <div><strong>Keycaps:</strong>
                        <ul>
                            {controller.keycapsDesc.map((keycap, keycapIndex) => (
                                <li key={keycapIndex}>{keycap}</li>
                            ))}
                        </ul>
                    </div> 
*/}

                    <div><strong>Keycaps:</strong>
                        <ul>
                            {controller.keycapsDesc.map((keycap, keycapIndex) => (
                                <li key={keycapIndex}>
                                    {keycap.description}
                                    <a href={keycap.link} target="_blank" rel="noopener noreferrer">
                                        ( Buy Now ) {/* or "Buy on " + keycap.seller if seller is available */}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div><strong>Similar Controllers:</strong>
                        <ul>
                            {controller.similarControllersDesc.map((similarController, similarControllerIndex) => (
                                <li key={similarControllerIndex}>
                                    {similarController.title}
                                    <a href={similarController.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {similarController.seller} */}
                                        ( Buy Now )
                                        {/* Buy on {seller.name} */}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div><strong>Accessories:</strong>
                        <ul>
                            {controller.accessoriesDesc.map((accessory, accessoryIndex) => (
                                <li key={accessoryIndex}>
                                    {accessory.description}
                                    <a href={accessory.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {accessory.seller} */}
                                        ( Buy Now )
                                        {/* Buy on {seller.name} */}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* <p><strong>Keycaps:</strong> {controller.keycapsDesc}</p> */}
                    {/* <p><strong>Accessories:</strong> {controller.accessoriesDesc}</p> */}
                    {/* <p><strong>Switches:</strong> {controller.switchesDesc}</p> */}

                    <div><strong>Stock Switches:</strong>
                        <ul>
                            {controller.switchesStockDesc.map((switchDetail, switchIndex) => (
                                <li key={switchIndex}>
                                    {switchDetail.description}
                                    <a href={switchDetail.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {switchDetail.seller} */}
                                        ( Buy Now )
                                        {/* Buy on {seller.name} */}
                                    </a>
                                    <br />
                                    <i>
                                    {switchDetail.notes ? ` - ${switchDetail.notes}` : ''}
                                    </i>
                                    <br />
                                    Style: 
                                    <ul>
                                        {switchDetail.styles.map((switchStyle, switchStyleIndex) => (
                                            <li key={switchStyleIndex}>{switchStyle}</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                            <br />
                        </ul>
                    </div>

                    <div>
                        <strong>Switch Upgrade Options: </strong>
                        <br />
                        <i>(I've tested to date or have been made aware of)</i>
                        <ul>
                            {controller.switchesUpgradeDesc.map((switchDetail, switchIndex) => (
                                <li key={switchIndex}>
                                    {switchDetail.description}
                                    <a href={switchDetail.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {switchDetail.seller} */}
                                        ( Buy Now )
                                        {/* Buy on {seller.name} */}
                                    </a>

{/* 
                                    <br />
                                    {switchDetail.notes ? ` - ${switchDetail.notes}` : ''}
*/}

{/* 
                                    <br />
                                    <i>
                                    {switchDetail.notes ? ` - ${switchDetail.notes}` : ''}
                                    </i>
*/}


<br />
                                    <i>
                                    {switchDetail.notes ? ` - ${switchDetail.notes}` : ''}
                                    </i>
                                    <br />
                                    Style: 
                                    <ul>
                                        {switchDetail.styles.map((switchStyle, switchStyleIndex) => (
                                            <li key={switchStyleIndex}>{switchStyle}</li>
                                        ))}
                                    </ul>

                                </li>
                            ))}
                        </ul>
                    </div>

                    <div><strong>Adapters:</strong>
                        <ul>
                            {controller.adaptersDesc.map((adapter, adapterIndex) => (
                                <li key={adapterIndex}>
                                    {adapter.description}
                                    <a href={adapter.link} target="_blank" rel="noopener noreferrer">
                                        {/* Buy on {adapter.seller} */}
                                          ( Buy Now )
                                        {/* Buy on {seller.name} */}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
{/*                     
                    <div><strong>Adapters:</strong>
                        <ul>
                            {controller.adaptersDesc.map((adapter, adapterIndex) => (
                                <li key={adapterIndex}>
                                    {adapter}
 */}


                    {/* 
                                                        <a href={seller.link} target="_blank" rel="noopener noreferrer">
                                                            Buy on {seller.name}
                                                        </a>                                
                    */}

{/* 
                                </li>
                            ))}
                        </ul>
                    </div>
 */}


                    {/* <p><strong>Adapters:</strong> {controller.adaptersDesc}</p> */}
                </div>
            )}
        </li>
    );
};

export default ControllerDetails;














// import React, { useState } from 'react';

// const ControllerDetails = ({ controller, index }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleOpen = () => setIsOpen(!isOpen);

//     return (
//         <li>
//             <div onClick={toggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
//                 <strong>{index + 1}. {controller.title}</strong>
//                 <span className="material-icons">
//                     {isOpen ? 'expand_less' : 'expand_more'}
//                 </span>
//             </div>
//             {isOpen && (
//                 <div>
//                     <p>{controller.description}</p>
//                     <p><strong>Sizes:</strong>
//                         <ul>
//                             {controller.sizesDesc.map((size, sizeIndex) => (
//                                 <li key={sizeIndex}>{size}</li>
//                             ))}
//                         </ul>
//                     </p>
//                     <p><strong>Controller Style:</strong> {controller.controllerstyleDesc}</p>
//                     <p><strong>Play Locations:</strong> {controller.playLocationsDesc}</p>
//                     <p><strong>Keycaps:</strong> {controller.keycapsDesc}</p>
//                     <p><strong>Accessories:</strong> {controller.accessoriesDesc}</p>
//                     <p><strong>Switches:</strong> {controller.switchesDesc}</p>
//                     <p><strong>Adapters:</strong> {controller.adaptersDesc}</p>
//                     <div className="links">
//                         <strong>Buy Links:</strong>
//                         <ul>
//                             {controller.sellers.map((seller, sellerIndex) => (
//                                 <li key={sellerIndex}>
//                                     <a href={seller.link} target="_blank" rel="noopener noreferrer">
//                                         Buy on {seller.name}
//                                     </a>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </div>
//             )}
//         </li>
//     );
// };

// export default ControllerDetails;









// import React, { useState } from 'react';


// const findDescription = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         return item ? item.description : 'Description not found';
//     }).filter(Boolean).join(', ');
// };

// const ControllerDetails = ({ controller, index }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleOpen = () => setIsOpen(!isOpen);

//     const sizesDescriptions = findDescription(controller.size, 'size');

//     console.log("controller", controller);

//     return (
//         <li>
//             <div onClick={toggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
//                 <strong>{index + 1}. {controller.title}</strong>
//                 <span className="material-icons">
//                     {isOpen ? 'expand_less' : 'expand_more'}
//                 </span>
//             </div>
//             {isOpen && (
//                 <div>
//                     <p>{controller.description}</p>
//                     {/* <p><strong>Sizes:</strong> {controller.sizesDesc}</p> */}
//                     {/* <p><strong>Sizes:</strong>
//                         <ul>
//                             {controller.sizesDesc.map((size, sizeIndex) => (
//                                 <li key={sizeIndex}>{size}</li>
//                             ))}
//                         </ul>
//                     </p> */}
//                     <p><strong>Sizes:</strong>
//                         <ul>
//                             {sizesDescriptions.map((desc, idx) => (
//                                 <li key={idx}>{desc}</li>
//                             ))}
//                         </ul>
//                     </p>

//                     <p><strong>Controller Style:</strong> {controller.controllerstyleDesc}</p>
//                     <p><strong>Play Locations:</strong> {controller.playLocationsDesc}</p>
//                     <p><strong>Keycaps:</strong> {controller.keycapsDesc}</p>
//                     <p><strong>Accessories:</strong> {controller.accessoriesDesc}</p>
//                     <p><strong>Switches:</strong> {controller.switchesDesc}</p>
//                     <p><strong>Adapters:</strong> {controller.adaptersDesc}</p>
//                     <div className="links">
//                         <strong>Buy Links:</strong>
//                         <ul>
//                             {controller.sellers.map((seller, sellerIndex) => (
//                                 <li key={sellerIndex}>
//                                     <a href={seller.link} target="_blank" rel="noopener noreferrer">
//                                         Buy on {seller.name}
//                                     </a>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </div>
//             )}
//         </li>
//     );
// };

// export default ControllerDetails;
