import React from 'react';
import SwitchSelection from '../components/SwitchSelection';
import '../pagestyles/SwitchSelectionPage.scss';
import Footer from '../components/Footer';

const SwitchSelectionPage = () => {
  return (
    <div className="selection-page">
      <SwitchSelection />
      <Footer />
    </div>
  );
};

export default SwitchSelectionPage;
