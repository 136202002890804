import React from 'react';
import SimilarControllerSelection from '../components/SimilarControllerSelection';
import '../pagestyles/SimilarControllerSelectionPage.scss';
import Footer from '../components/Footer';

const SimilarControllerSelectionPage = () => {
  return (
    <div className="selection-page">
      <SimilarControllerSelection />
      <Footer />
    </div>
  );
};

export default SimilarControllerSelectionPage;
