import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const ButtonCountSelection = () => {
  const [buttonCounts, setButtonCounts] = useState([]);
  const [selectedButtonCounts, setSelectedButtonCounts] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const counts = Array.from(
        new Set(
            data.brands.flatMap(brand =>
                brand.controllers.flatMap(controller => controller.buttoncountoptions)
            )
        )
    ).sort((a, b) => a - b);
    setButtonCounts(counts);
  }, []);

  const handleButtonCountChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(parseInt(options[i].value));
      }
    }
    setSelectedButtonCounts(selected);
  };

  const handleFilter = () => {
    if (selectedButtonCounts.length === 0) return;
    const filteredResults = filterControllers(selectedButtonCounts, 'buttoncountoptions');
    setResults(filteredResults);
  };

  return (
    <div className="search-n-results">
      <h4 className="title">Button Count Selection</h4>

      <div className="filter-container">
        <label htmlFor="button-count-filter">Select Button Counts:</label>
        <br />
        <select
          id="button-count-filter"
          multiple
          value={selectedButtonCounts}
          onChange={handleButtonCountChange}
          className="multi-select"
        >
          {buttonCounts.map((count) => (
            <option key={count} value={count}>
              {count} Buttons
            </option>
          ))}
        </select>
        <br />
        <button onClick={handleFilter} className="filter-button">Filter</button>
      </div>

        <div className="results-container">
          <h2>{results.length} Brands Found:</h2>
          {results.length > 0 ? (
              results.map((result, index) => (
                  <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
              ))
          ) : (
              <p className="no-results">No results found. Please select button count.</p>
          )}
        </div>
    </div>
  );
};

export default ButtonCountSelection;
