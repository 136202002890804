import React from 'react';
import ControllerDetails from './ControllerDetails';

const ResultCard = ({ index, brandData, filteredControllers }) => {
    return (
        <div className="result-card">
            <hr />
            <h2>{index}. {brandData.brandname}</h2>
            <p>{brandData.description}</p>
            <ul className="brand-links">
                {brandData.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                            {link.description}
                        </a>
                    </li>
                ))}
            </ul>
            <h4>{filteredControllers.length} {brandData.brandname} Controllers Found:</h4>
            <ul>
                {filteredControllers.map((controller, controllerIndex) => (
                    <ControllerDetails key={controllerIndex} controller={controller} index={controllerIndex} />
                ))}
            </ul>
        </div>
    );
};

export default ResultCard;















// import React from 'react';
// import ControllerDetails from './ControllerDetails';

// const ResultCard = ({ index, brandData, filteredControllers }) => {
//     return (
//         <div className="result-card">
//             <h2>{index}. {brandData.brandname}</h2>
//             <p>{brandData.description}</p>
//             <ul className="brand-links">
//                 {brandData.links.map((link, linkIndex) => (
//                     <li key={linkIndex}>
//                         <a href={link.link} target="_blank" rel="noopener noreferrer">
//                             {link.description}
//                         </a>
//                     </li>
//                 ))}
//             </ul>
//             <h4>{filteredControllers.length} {brandData.brandname} Controllers Found:</h4>
//             <ul>
//                 {filteredControllers.map((controller, controllerIndex) => (
//                     <ControllerDetails key={controllerIndex} controller={controller} index={controllerIndex} />
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default ResultCard;





















// import React from 'react';
// import ControllerDetails from './ControllerDetails';

// const ResultCard = ({ index, brandData, filteredControllers }) => {
//     return (
//          <div className="result-card">
//              <h2>{index}. {brandData.brandname}</h2>
//              <p>{brandData.description}</p>
//              <ul className="brand-links">
//                  {brandData.links.map((link, linkIndex) => (
//                      <li key={linkIndex}>
//                          <a href={link.link} target="_blank" rel="noopener noreferrer">
//                              {link.description}
//                          </a>
//                      </li>
//                  ))}
//              </ul>
//              <h4>{filteredControllers.length} {brandData.brandname} Controllers Found:</h4>

//             <ul>
//                 {filteredControllers.map((controller, controllerIndex) => (
//                     <ControllerDetails key={controllerIndex} controller={controller} index={controllerIndex} />
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default ResultCard;


// import React, { useState } from 'react';

// const ResultCard = ({ index, brandData, filteredControllers }) => {
//     const [openControllerIndex, setOpenControllerIndex] = useState(null);

//     const toggleController = (index) => {
//         if (openControllerIndex === index) {
//             setOpenControllerIndex(null);
//         } else {
//             setOpenControllerIndex(index);
//         }
//     };

//     return (
//         <div className="result-card">
//             <h2>{index}. {brandData.brandname}</h2>
//             <p>{brandData.description}</p>
//             <ul className="brand-links">
//                 {brandData.links.map((link, linkIndex) => (
//                     <li key={linkIndex}>
//                         <a href={link.link} target="_blank" rel="noopener noreferrer">
//                             {link.description}
//                         </a>
//                     </li>
//                 ))}
//             </ul>
//             <h4>{filteredControllers.length} {brandData.brandname} Controllers Found:</h4>
//             <ul>
//                 {filteredControllers.map((controller, controllerIndex) => (
//                     <li key={controllerIndex}>
//                         <div onClick={() => toggleController(controllerIndex)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
//                             <strong>{controllerIndex + 1}. {controller.title}</strong>
//                             <span className="material-icons">
//                                 {openControllerIndex === controllerIndex ? 'expand_less' : 'expand_more'}
//                             </span>
//                         </div>
//                         {openControllerIndex === controllerIndex && (
//                             <div>
//                                 <p>{controller.description}</p>
//                                 <p><strong>Sizes:</strong> {controller.sizesDesc}</p>
//                                 <p><strong>Controller Style:</strong> {controller.controllerstyleDesc}</p>
//                                 <p><strong>Play Locations:</strong> {controller.playLocationsDesc}</p>
//                                 <p><strong>Keycaps:</strong> {controller.keycapsDesc}</p>
//                                 <p><strong>Accessories:</strong> {controller.accessoriesDesc}</p>
//                                 <p><strong>Switches:</strong> {controller.switchesDesc}</p>
//                                 <p><strong>Adapters:</strong> {controller.adaptersDesc}</p>
//                                 <div className="links">
//                                     <strong>Buy Links:</strong>
//                                     <ul>
//                                         {controller.sellers.map((seller, sellerIndex) => (
//                                             <li key={sellerIndex}>
//                                                 <a href={seller.link} target="_blank" rel="noopener noreferrer">
//                                                     Buy on {seller.name}
//                                                 </a>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>
//                         )}
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default ResultCard;
