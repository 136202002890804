import React, { useState, useEffect } from 'react';
import { filterSimilarControllers } from '../utils/filterSimilarControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const SimilarControllerSelection = () => {
    const [brands, setBrands] = useState([]);
    // const [selectedControllers, setSelectedControllers] = useState([]);
    const [selectedControllers, setSelectedControllers] = useState(''); 
    const [results, setResults] = useState([]);

    useEffect(() => {
        const sortedBrands = data.brands
            .map(brand => ({
                ...brand,
                controllers: brand.controllers.sort((a, b) => a.title.localeCompare(b.title))
            }))
            .sort((a, b) => a.brandname.localeCompare(b.brandname));
        setBrands(sortedBrands);
    }, []);

    const handleControllerChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedControllers(selected);
    };

    const handleFilter = () => {
        if (selectedControllers.length === 0) return;
        const filteredResults = filterSimilarControllers(selectedControllers, 'similarcontrollers');
        setResults(filteredResults);
    };
    
    return (
        <div className="search-n-results">
            <h4 className="title">Selected Controller Is Similar To: </h4>

            <div className="filter-container">
                <label htmlFor="controller-filter">Select Controller to Find Similar Controllers:</label>
                <i>Find controllers that this controller is similar to:</i>
                <br />
                <select
                    id="controller-filter"
                    value={selectedControllers}
                    onChange={handleControllerChange}
                    // multiple
                    // className="multi-select"
                >
                    {brands.map(brand => (
                        <optgroup key={brand.brandid} label={brand.brandname}>
                            {brand.controllers.map(controller => (
                                <option key={controller.controllerid} value={controller.controllerid}>
                                    {brand.brandname}: {controller.title}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Results Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />
                    ))
                ) : (
                    <p className="no-results">No results found. Please select a controller.</p>
                )}
            </div>
        </div>
    );
};

export default SimilarControllerSelection;






























// import React, { useState, useEffect } from 'react';
// import { filterSimilarControllers } from '../utils/filterSimilarControllers';
// import ResultCard from './ResultCard';
// import data from '../data/data-v3.json';

// const SimilarControllerSelection = () => {
//     const [brands, setBrands] = useState([]);
//     const [selectedControllers, setSelectedControllers] = useState([]);
//     const [results, setResults] = useState([]);

//     useEffect(() => {
//         const sortedBrands = data.brands
//             .map(brand => ({
//                 ...brand,
//                 controllers: brand.controllers.sort((a, b) => a.title.localeCompare(b.title))
//             }))
//             .sort((a, b) => a.brandname.localeCompare(b.brandname));
//         setBrands(sortedBrands);
//     }, []);

//     const handleControllerChange = (e) => {
//         const { options } = e.target;
//         const selected = [];
//         for (let i = 0; i < options.length; i++) {
//             if (options[i].selected) {
//                 selected.push(parseInt(options[i].value));
//             }
//         }
//         setSelectedControllers(selected);
//     };

//     const handleFilter = () => {
//         if (selectedControllers.length === 0) return;
//         const filteredResults = filterSimilarControllers(selectedControllers, 'similarcontrollers');
//         // const filteredResults = filterSimilarControllers(selectedControllers, 'controllerid');
//         setResults(filteredResults);
//     };
    
//     // const handleFilter = () => {
//     //     if (selectedControllers.length === 0) return;
//     //     const filteredResults = selectedControllers.map(id => {
//     //         return data.brands
//     //             .flatMap(brand => brand.controllers.filter(controller => controller.controllerid === id))
//     //             .map(controller => ({ brandData: data.brands.find(brand => brand.controllers.some(c => c.controllerid === id)), filteredControllers: [controller] }));
//     //     }).flat();
//     //     setResults(filteredResults);
//     // };

//     return (
//         <div className="search-n-results">
//             <h4 className="title">Similar Controller Selection</h4>

//             <div className="filter-container">
//                 <label htmlFor="controller-filter">Select Controller to Find Similar Controllers:</label>
//                 <br />
//                 <select
//                     id="controller-filter"
//                     multiple
//                     value={selectedControllers}
//                     onChange={handleControllerChange}
//                     className="multi-select"
//                 >
//                     {brands.map(brand => (
//                         <optgroup key={brand.brandid} label={brand.brandname}>
//                             {brand.controllers.map(controller => (
//                                 <option key={controller.controllerid} value={controller.controllerid}>
//                                     {brand.brandname}: {controller.title}
//                                 </option>
//                             ))}
//                         </optgroup>
//                     ))}
//                 </select>
//                 <br />
//                 <button onClick={handleFilter} className="filter-button">Filter</button>
//             </div>

//             <div className="results-container">
//                 <h2>{results.length} Results Found:</h2>
//                 {results.length > 0 ? (
//                     results.map((result, index) => (
//                         <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />
//                     ))
//                 ) : (
//                     <p className="no-results">No results found. Please select a controller.</p>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default SimilarControllerSelection;
