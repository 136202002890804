import React, { useEffect, useState } from 'react';
import { filterSimilarControllers2 } from '../utils/filterSimilarControllers2';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const SimilarControllerSelection2 = () => {
    const [brands, setBrands] = useState([]);
    // const [selectedControllers, setSelectedControllers] = useState([]);
    const [selectedControllers, setSelectedControllers] = useState(''); 
    const [results, setResults] = useState([]);

    useEffect(() => {
        const sortedBrands = data.brands
            .map(brand => ({
                ...brand,
                controllers: brand.controllers.sort((a, b) => a.title.localeCompare(b.title))
            }))
            .sort((a, b) => a.brandname.localeCompare(b.brandname));
        setBrands(sortedBrands);
    }, []);

    const handleControllerChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedControllers(selected);
    };

    // const handleFilter = () => {
    //     if (!selectedControllerId) return;
    //     const filteredResults = filterSimilarControllers2([parseInt(selectedControllerId)]);
    //     setResults(filteredResults);
    // };

    const handleFilter = () => {
        if (selectedControllers.length === 0) return;
        // const filteredResults = filterSimilarControllers2(selectedControllers, 'similarcontrollers');
        const filteredResults = filterSimilarControllers2([parseInt(selectedControllers)]);
        setResults(filteredResults);
    };

    return (
        <div className="similar-controller-selection">
            <h4 className="title">Controllers Similar To The Selected Controller:</h4>

            <div className="filter-container">
                <label htmlFor="controller-filter">Select a Controller to Find Similar Controllers:</label>
                    {/* <label htmlFor="controller-filter">Controllers Similar To The Selected Controller:</label> */}
                <i>Find controllers that are similar to the selected controller:</i>
                <br />
                <select
                    id="controller-filter"
                    value={selectedControllers}
                    onChange={handleControllerChange}
                    // multiple
                    // className="multi-select"




                    // id="controller-filter"
                    // value={selectedControllerId}
                    // onChange={(e) => setSelectedControllerId(e.target.value)} 
                    // // multiple
                    // // className="multi-select"

                >

                    {brands.map(brand => (
                        <optgroup key={brand.brandid} label={brand.brandname}>
                            {brand.controllers.map(controller => (
                                <option key={controller.controllerid} value={controller.controllerid}>
                                    {brand.brandname}: {controller.title}
                                </option>
                            ))}
                        </optgroup>
                    ))}


{/* 
                    <option value="">Select Controller</option>
                    {data.brands.flatMap(brand => brand.controllers).map(controller => (
                        <option key={controller.controllerid} value={controller.controllerid}>
                            {controller.title}
                        </option>
                    ))}
 */}

                </select>
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Results Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />
                    ))
                ) : <p>No similar controllers found.</p>}
            </div>
        </div>
    );
};

export default SimilarControllerSelection2;



































// import React, { useState, useEffect } from 'react';
// import { filterSimilarControllers2 } from '../utils/filterSimilarControllers2';
// import ResultCard from './ResultCard';
// import data from '../data/data-v3.json';

// const SimilarControllerSelection2 = () => {
//     const [selectedControllerId, setSelectedControllerId] = useState('');
//     const [results, setResults] = useState([]);

//     useEffect(() => {
//         if (selectedControllerId) {
//             const filteredResults = filterSimilarControllers2([parseInt(selectedControllerId)]);
//             setResults(filteredResults);
//         } else {
//             setResults([]);
//         }
//     }, [selectedControllerId]);

//     return (
//         <div className="similar-controller-selection">
//             {/* <h4 className="title">Similar Controller Selection</h4> */}
//             {/* <h4 className="title">Controllers These are Similar To Selection:</h4> */}
//             <h4 className="title">Controllers Similar To This Selected Controller: </h4>
//             <h6 className="title">
//               <i>Find controllers that are similar to this controller:</i>
//             </h6>

//             <div className="filter-container">
//                 <label htmlFor="controller-filter">Select Controllers Similar To This Selected Controller:</label>
//                 <br />
//                 <select
//                     id="controller-filter"
//                     // multiple
//                     value={selectedControllerId || ''}
//                     onChange={(e) => setSelectedControllerId(e.target.value)} 
//                     className="multi-select"
//                 >
//                     <option value="">Select Controller</option>
//                     {data.brands.flatMap(brand => brand.controllers).map(controller => (
//                         <option key={controller.controllerid} value={controller.controllerid}>
//                             {controller.title}
//                         </option>
//                     ))}

// {/* 
//                 </select>
//             </div>
//  */}

//                 </select>
//                 <br />
//                 <button onClick={handleFilter} className="filter-button">Filter</button>
//             </div>


            
//             <div>
//                 {results.length > 0 ? (
//                     results.map((result, index) => (
//                         <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />
//                     ))
//                 ) : <p>No similar controllers found.</p>}
//             </div>
//         </div>
//     );
// };

// export default SimilarControllerSelection2;




























// import React, { useState, useEffect } from 'react';
// import { filterSimilarControllers2 } from '../utils/filterSimilarControllers2';
// import ResultCard from './ResultCard';
// import data from '../data/data-v3.json';

// const SimilarControllerSelection2 = () => {
//     const [selectedControllerId, setSelectedControllerId] = useState('');  // Use empty string instead of null
//     const [results, setResults] = useState([]);

//     useEffect(() => {
//         if (selectedControllerId) {
//             const filteredResults = filterSimilarControllers2([parseInt(selectedControllerId)]);
//             setResults(filteredResults);
//         } else {
//             setResults([]);  // Clear results when no controller is selected
//         }
//     }, [selectedControllerId]);

//     return (
//         <div className="similar-controller-selection">
//             <h4>Select a Controller</h4>
//             <select
//                 onChange={(e) => setSelectedControllerId(e.target.value)}
//                 value={selectedControllerId}
//                 className="controller-select"
//             >
//                 <option value="">Select Controller</option>
//                 {data.brands.flatMap(brand => brand.controllers).map(controller => (
//                     <option key={controller.controllerid} value={controller.controllerid}>
//                         {controller.title}
//                     </option>
//                 ))}
//             </select>
//             <div>
//                 {results.length > 0 ? results.map((result, index) => (
//                     <ResultCard key={index} controller={result} />
//                 )) : <p>No similar controllers found.</p>}
//             </div>
//         </div>
//     );
// };

// export default SimilarControllerSelection2;






// import React, { useState, useEffect } from 'react';
// import { filterSimilarControllers2 } from '../utils/filterSimilarControllers2';
// import ResultCard from './ResultCard';
// import data from '../data/data-v3.json';

// const SimilarControllerSelection2 = () => {
//     const [selectedControllerId, setSelectedControllerId] = useState(null);
//     const [results, setResults] = useState([]);

//     useEffect(() => {
//         if (selectedControllerId) {
//             const filteredResults = filterSimilarControllers2([selectedControllerId]);
//             setResults(filteredResults);
//         }
//     }, [selectedControllerId]);

//     return (
//         <div className="similar-controller-selection">
//             <h4>Select a Controller</h4>
//             <select onChange={(e) => setSelectedControllerId(parseInt(e.target.value))} value={selectedControllerId}>
//                 <option value="">Select Controller</option>
//                 {data.brands.flatMap(brand => brand.controllers).map(controller => (
//                     <option key={controller.controllerid} value={controller.controllerid}>
//                         {controller.title}
//                     </option>
//                 ))}
//             </select>
//             <div>
//                 {results.length > 0 ? results.map((result, index) => (
//                     <ResultCard key={index} index={index + 1} brandData={result.brandName} filteredControllers={result} />
//                 )) : <p>No similar controllers found.</p>}
//             </div>
//         </div>
//     );
// };

// export default SimilarControllerSelection2;
