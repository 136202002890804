import data from '../data/data-v3.json';

const isControllerIdValid = (id) => {
    return data.brands.some(brand =>
        brand.controllers.some(controller => controller.controllerid === id)
    );
};

const getControllerDetailsById = (id) => {
    for (const brand of data.brands) {
        const controller = brand.controllers.find(controller => controller.controllerid === id);
        if (controller) {
            return {
                title: controller.title,
                description: controller.description,
                brand: brand.brandname
            };
        }
    }
    return null;
};

const findDescription = (ids, type) => {
    if (!data[type]) {
        console.error(`findDescription error: data[${type}] is undefined`);
        return ids.map(() => 'Description not found'); // Return 'Description not found' for each id as a fallback
    }

    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item) {
            console.error(`findDescription error: No item found for id ${id} in type ${type}`);
        }
        return item ? item.description : 'Description not found';
    });
};

const findSwitchesDetails = (ids, type) => {
    const details = ids.map(id => {
        const item = data[type].find(item => item.switchid === parseInt(id));
        if (!item) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }

        const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
        const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

        return {
            description: item.description,
            notes: item.notes,
            styles: item.styles,
            link: link,
            seller: seller
        };
    });

    details.sort((a, b) => a.description.localeCompare(b.description));
    return details;
};

const findAccessoriesDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item.accessoriesid === parseInt(id));

        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findKeycapsDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findAdaptersDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

export const filterControllers = (selectedIds, filterKey) => {
    // console.log("fC - selectedIds", selectedIds)
    // console.log("fC - filterKey", filterKey)

    const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

    return sortedBrands.map(brand => {
        // console.log("fC - brand", brand)

        const filteredControllers = brand.controllers.filter(controller => 
            selectedIds.some(id => controller[filterKey].includes(id))
        ).sort((a, b) => a.title.localeCompare(b.title));  // Sorting controllers by title
    
        // console.log("fC - filteredControllers", filteredControllers)
        // console.log("fC - controller", controller)

        if (filteredControllers.length > 0) {
            const enhancedControllers = filteredControllers.map(controller => {
            const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
                isControllerIdValid(similarId)
            );

            const similarControllersDesc = validSimilarControllers.map(similarId => 
                getControllerDetailsById(similarId)
            ).filter(details => details !== null);  // Filter out null values if any

            return {                
                ...controller,
                controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
                sizesDesc: findDescription(controller.sizes, 'sizes'),
                playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
                keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
                accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),                
                        switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
                switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
                switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
                adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
                similarControllersDesc: similarControllersDesc
                };
            });

            return {
                brandData: brand,
                filteredControllers: enhancedControllers
            };
        }
        return null;
    }).filter(Boolean);
};

































// import data from '../data/data-v3.json';

// const isControllerIdValid = (id) => {
//     return data.brands.some(brand =>
//         brand.controllers.some(controller => controller.controllerid === id)
//     );
// };

// const getControllerDetailsById = (id) => {
//     for (const brand of data.brands) {
//         const controller = brand.controllers.find(controller => controller.controllerid === id);
//         if (controller) {
//             return {
//                 title: controller.title,
//                 description: controller.description,
//                 brand: brand.brandname
//                 // Include other details as needed
//             };
//         }
//     }
//     return null; // Return null or a default object if no controller is found
// };

// const findDescription = (ids, type) => {
//     // console.log("findDescription - ids:", ids, "type:", type);

//     // Check if the data[type] is defined
//     if (!data[type]) {
//         console.error(`findDescription error: data[${type}] is undefined`);
//         return ids.map(() => 'Description not found'); // Return 'Description not found' for each id as a fallback
//     }

//     return ids.map(id => {
//     // console.log("fC - ids", ids)
//     // console.log("fC - type", type)
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         // const item = data[type].find(item => item[type + 'id'] === parseInt(id));
//         if (!item) {
//             console.error(`findDescription error: No item found for id ${id} in type ${type}`);
//         }
//         return item ? item.description : 'Description not found';
//     });
// };

// // const findSwitchesDetails = (ids, type) => {
// //     return ids.map(id => {
// //         // switchid
// //         // stockswitches
// //         // switchesupgrades
// //         const item = data[type].find(item => item.switchid === parseInt(id));
// //         // const item = data[type].find(item => item.stockswitches === parseInt(id));
// //         // const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
// //         if (!item || !item.links || item.links.length === 0) {
// //             return { description: 'Description not found', link: '#', seller: 'N/A' };
// //         }
// //         return {
// //             description: item.description,
// //             link: item.links[0].link,
// //             seller: item.links[0].seller
// //         };
// //     });
// // };


// const findSwitchesDetails = (ids, type) => {
//     const details = ids.map(id => {
//         const item = data[type].find(item => item.switchid === parseInt(id));
//         if (!item) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         // Handle cases where there might be no links provided
//         const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
//         const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

//         return {
//             description: item.description,
//             notes: item.notes,
//             styles: item.styles,
//             link: link,
//             seller: seller
//         };
//     });

//     // Sort the details array by description alphabetically
//     details.sort((a, b) => a.description.localeCompare(b.description));
//     return details;
// };


// // const findSwitchesDetails = (ids, type) => {
// //     return ids.map(id => {
// //         const item = data[type].find(item => item.switchid === parseInt(id));
// //         if (!item) {
// //             return { description: 'Description not found', link: '#', seller: 'N/A' };
// //         }
// //         // Handle cases where there might be no links provided
// //         const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
// //         const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

// //         return {
// //             description: item.description,
// //             notes: item.notes,
// //             styles: item.styles,
// //             link: link,
// //             seller: seller
// //         };
// //     });
// // };




// // const findAccessoriesDetails = (ids, type) => {
// //     if (!data[type]) {
// //         console.error(`Data for type ${type} not found`);
// //         return ids.map(() => ({ description: 'Description not found', link: '#', seller: 'N/A' }));
// //     }

// //     return ids.map(id => {
// //         // Using .accessoryid directly ensures that the property matches exactly with your data schema
// //         const item = data[type].find(item => item.accessoriesid === parseInt(id));
// //         if (!item) {
// //             console.error(`No item found for id ${id} in type ${type}`);
// //             return { description: 'Description not found', link: '#', seller: 'N/A' };
// //         }
// //         return item.links && item.links.length > 0 ? {
// //             description: item.description,
// //             link: item.links[0].link,
// //             seller: item.links[0].seller
// //         } : {
// //             description: item.description,
// //             link: '#',
// //             seller: 'N/A'
// //         };
// //     });
// // };

//                 // const findAccessoriesDetails = (ids, type) => {
//                 //     if (!data[type]) {
//                 //         console.error(`Data for type ${type} not found`);
//                 //         return ids.map(() => ({ description: 'Description not found', link: '#', seller: 'N/A' }));
//                 //     }

//                 //     return ids.map(id => {
//                 //         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//                 //         if (!item) {
//                 //             console.error(`No item found for id ${id} in type ${type}`);
//                 //             return { description: 'Description not found', link: '#', seller: 'N/A' };
//                 //         }
//                 //         return item.links && item.links.length > 0 ? {
//                 //             description: item.description,
//                 //             link: item.links[0].link,
//                 //             seller: item.links[0].seller
//                 //         } : {
//                 //             description: item.description,
//                 //             link: '#',
//                 //             seller: 'N/A'
//                 //         };
//                 //     });
//                 // };

// const findAccessoriesDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item.accessoriesid === parseInt(id));
//         // const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };

// const findKeycapsDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };

// const findAdaptersDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };


// // const findAdaptersDetails = (ids, type) => {
// //     if (!data[type]) {
// //         console.error(`Data for type ${type} not found`);
// //         return ids.map(() => ({ description: 'Description not found', link: '#', seller: 'N/A' }));
// //     }

// //     return ids.map(id => {
// //         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
// //         if (!item) {
// //             console.error(`No item found for id ${id} in type ${type}`);
// //             return { description: 'Description not found', link: '#', seller: 'N/A' };
// //         }
// //         return {
// //             description: item.description,
// //             link: (item.links && item.links.length > 0) ? item.links[0].link : '#',
// //             seller: (item.links && item.links.length > 0) ? item.links[0].seller : 'N/A'
// //         };
// //     });
// // };




// // const findDescription = (ids, type) => {
// //     return ids.map(id => {
// //         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
// //         return item ? item.description : null;
// //     }).filter(Boolean);
// //     //     return item ? item.description : undefined;
// //     // }).filter(Boolean).join(', ');
// // };

// // const findDescription = (ids, type) => {
// //     console.log("fC - ids", ids)
// //     console.log("fC - type", type)
// //     return ids.map(id => {
// //         console.log("fC - type.slice(0, -1) + 'id'", type.slice(0, -1) + 'id')
// //         // const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
// //         const item = data[type].find(item => item[type + 'id'] === parseInt(id));
// //         console.log("fC - item", item)
// //         if (!item) {
// //             console.log(`fC - No item found for id ${id} in type ${type}`);
// //         }
// //         return item ? item.description : 'Description not found';
// //     });
// // };




// // export const filterControllers = (selectedIds, filterKey) => {
// //     console.log("Selected Styles IDs:", selectedIds); // Log to check what is being received

// //     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

// //     return sortedBrands.map(brand => {
// //         console.log("Brand:", brand.brandname); // Log each brand being processed

// //         const filteredControllers = brand.controllers.filter(controller => {
// //             console.log("Controller Styles for", controller.title + ":", controller[filterKey]); // Log the styles array of each controller
// //             return selectedIds.some(id => controller[filterKey]?.includes(id));
// //         });

// //         if (filteredControllers.length > 0) {
// //             const enhancedControllers = filteredControllers.map(controller => ({
// //                 ...controller,
// //                 controllerstyleDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
// //                 sizesDesc: findDescription(controller.sizes, 'sizes'),
// //                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
// //                 keycapsDesc: findDescription(controller.keycaps, 'keycaps'),
// //                 accessoriesDesc: findDescription(controller.accessories, 'accessories'),
// //                 switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
// //                 adaptersDesc: findDescription(controller.adapters, 'adapters')
// //             }));

// //             console.log("Filtered Controllers for", brand.brandname + ":", enhancedControllers); // Log the filtered and enhanced controllers
// //             return {
// //                 brandData: brand,
// //                 filteredControllers: enhancedControllers
// //             };
// //         }
// //         return null;
// //     }).filter(Boolean);
// // };










// // export const filterControllers = (selectedIds, filterKey) => {
// //     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

// //     return sortedBrands.map(brand => {
// //         const filteredControllers = brand.controllers.filter(controller =>
// //             selectedIds.some(id => controller[filterKey + 's']?.includes(id)) // Ensure the key is plural and check for null safety
// //         );

// //         if (filteredControllers.length > 0) {
// //             const enhancedControllers = filteredControllers.map(controller => ({
// //                 ...controller,
// //                 controllerstyleDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
// //                 sizesDesc: findDescription(controller.sizes, 'sizes'),
// //                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
// //                 keycapsDesc: findDescription(controller.keycaps, 'keycaps'),
// //                 accessoriesDesc: findDescription(controller.accessories, 'accessories'),
// //                 switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
// //                 adaptersDesc: findDescription(controller.adapters, 'adapters')
// //             }));

// //             return {
// //                 brandData: brand,
// //                 filteredControllers: enhancedControllers
// //             };
// //         }
// //         return null;
// //     }).filter(Boolean);
// // };





// export const filterControllers = (selectedIds, filterKey) => {
//     // console.log("fC - selectedIds", selectedIds)
//     // console.log("fC - filterKey", filterKey)

//     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

//     // return data.brands.map(brand => {
//     return sortedBrands.map(brand => {
//         // console.log("fC - brand", brand)

//         const filteredControllers = brand.controllers.filter(controller => 
//             // selectedIds.some(id => controller[filterKey].includes(id.toString()))
//             selectedIds.some(id => controller[filterKey].includes(id))
//         ).sort((a, b) => a.title.localeCompare(b.title));  // Sorting controllers by title
//         // );
    
//         // console.log("fC - filteredControllers", filteredControllers)
//         // console.log("fC - controller", controller)

//         if (filteredControllers.length > 0) {
//         //     const enhancedControllers = filteredControllers.map(controller => {

//             //  // Filter similarControllers to include only valid controller IDs
//             //  const validSimilarControllers = controller.similarcontrollers.filter(similarId =>
//             //     isControllerIdValid(similarId)
//             // );
            
//             const enhancedControllers = filteredControllers.map(controller => {
//             // Ensure similarcontrollers is an array before filtering, default to empty array if undefined
//             const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
//                 isControllerIdValid(similarId)
//             );

//             const similarControllersDesc = validSimilarControllers.map(similarId => 
//                 getControllerDetailsById(similarId)
//             ).filter(details => details !== null);  // Filter out null values if any

//             // const enhancedControllers = filteredControllers.map(controller => ({

//             return {                
//                 ...controller,
//                 controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
//                 sizesDesc: findDescription(controller.sizes, 'sizes'),
//                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
//                 keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
//                 // keycapsDesc: findDescription(controller.keycaps, 'keycaps'),
//                 accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
                
//                         switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
                
//                 switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
//                 switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),

//                 // stockSwitchDetails = findSwitchesDetails(controller.stockswitches, 'switches');
//                 // upgradeSwitchDetails = findSwitchesDetails(controller.switchesupgrades, 'switches');

//                 adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
//                 // accessoriesDesc: findDescription(controller.accessories, 'accessories'),
//                 // switchesDesc: findDescription(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
//                 // adaptersDesc: findDescription(controller.adapters, 'adapters'),

//                 // similarControllersDesc: validSimilarControllers
//                 similarControllersDesc: similarControllersDesc

//                 };
//             });

//             // console.log("fC - enhancedControllers", enhancedControllers)
//             // console.log("fC - brand", brand)
//             // console.log("fC - filteredControllers", filteredControllers)


//             return {
//                 brandData: brand,
//                 filteredControllers: enhancedControllers
//             };
//         }
//         return null;
//     }).filter(Boolean);
// };



