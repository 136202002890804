import React, { useState, useEffect } from "react";

const AddControllerForm = () => {
  const [controllerData, setControllerData] = useState({
    controllerid: 0,
    brand: 0,
    title: "",
    description: "",
    cost: [],
    models: [],
    comments: "",
    size: [],
    screen: 1,
    passthroughport: 1,
    powerports: 1,
    powerporttype: 2,

    dimensions: "200mmx196mmx12mm", 
    customarttop: 1, 
    customartbottom: 1, 
    artpapersize: "A5  | 8.5x11",

    controllerstyle: [],
    stockswitches: [],
    switchesupgrades: [],
    keycaps: [],
    os: [],
    playlocation: [],
    buttoncountoptions: [],
    supportlinks: [{ link: "", notes: "" }],
    similarcontroller: [{ brandid: 0, controllerid: 0, comments: "", similarfingerspacing: 1 }],
    sellers: [{ name: "", link: "" }],
    images: [{ link: "" }],
    adapters: [],
    accessories: [],
  });

  const [jsonData, setJsonData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [switches, setSwitches] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [keycapsOptions, setKeycapsOptions] = useState([]);
  const [controllerStyleOptions, setControllerStyleOptions] = useState([]);
  const [adaptersOptions, setAdaptersOptions] = useState([]);
  const [accessoriesOptions, setAccessoriesOptions] = useState([]);
  const [osOptions, setOsOptions] = useState([]);
  const [playLocationOptions, setPlayLocationOptions] = useState([]);
  const [costInput, setCostInput] = useState("");

  useEffect(() => {
    fetch("data-v3.json")
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setJsonData(data);
        setBrands(data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname)));
  
        const allControllers = data.brands.flatMap(brand =>
          brand.controllers.map(controller => ({
            ...controller,
            brandid: parseInt(brand.brandid),
            controllerid: parseInt(controller.controllerid)
          }))
        ).sort((a, b) => a.title.localeCompare(b.title));
        setControllers(allControllers);
  
        const maxControllerId = allControllers.reduce((max, controller) => controller.controllerid > max ? controller.controllerid : max, 0);
        setControllerData(prevState => ({
          ...prevState,
          controllerid: maxControllerId + 1
        }));

        setSwitches(data.switches.sort((a, b) => a.description.localeCompare(b.description)));
        setSizeOptions(data.sizes.sort((a, b) => a.description.localeCompare(b.description)));
        setKeycapsOptions(data.keycaps.sort((a, b) => a.description.localeCompare(b.description)));
        
        if (data.controllerstyles && Array.isArray(data.controllerstyles)) {
          const sortedControllerStyles = data.controllerstyles.sort((a, b) => {
            if (typeof a.description === 'string' && typeof b.description === 'string') {
              return a.description.localeCompare(b.description);
            }
            return 0;
          });
          setControllerStyleOptions(sortedControllerStyles);
        }
      
        setOsOptions(data.os.sort((a, b) => a.name.localeCompare(b.name)));
        setPlayLocationOptions(data.playlocations.sort((a, b) => a.name.localeCompare(b.name)));

        setAdaptersOptions(data.adapters.sort((a, b) => a.name.localeCompare(b.name)));
        setAccessoriesOptions(data.accessories.sort((a, b) => a.name.localeCompare(b.name)));
     
      })
      .catch(error => {
        console.error("Failed to load JSON data", error);
      });
  }, []);

  
  const handleInputChange = (field, value) => {
    setControllerData({ ...controllerData, [field]: value });
  };

  const handleInputChangeArray = (field, value) => {
    if (field === "cost") {
      setControllerData({ ...controllerData, [field]: value });
    } else if (field === "models") {
      setControllerData({ ...controllerData, [field]: value.split(",").map(model => model.trim()) });
    } else {
      const arrayValues = value.split(",").map(item => item.trim()).filter(item => item !== "");
      setControllerData({ ...controllerData, [field]: arrayValues });
    }
  };
  
  const handleMultiSelectChange = (field, values) => {
    setControllerData({ ...controllerData, [field]: values });
  };

  const handleCostBlur = () => {
    const costsArray = costInput.split(",").map(cost => parseFloat(cost.trim()).toFixed(2));
    setControllerData({...controllerData, cost: costsArray});
  };

  const handleAddSupportLink = () => {
    const lastSupportLink = controllerData.supportlinks[controllerData.supportlinks.length - 1];
    if (lastSupportLink.link !== "" || lastSupportLink.notes !== "") {
      setControllerData({
        ...controllerData,
        supportlinks: [...controllerData.supportlinks, { link: "", notes: "" }]
      });
    }
  };

  const handleAddSimilarController = () => {
    const lastSimilarController = controllerData.similarcontroller[controllerData.similarcontroller.length - 1];

    if (
        lastSimilarController.brandid !== 0 && 
        lastSimilarController.controllerid !== 0 && 
        lastSimilarController.similarfingerspacing !== ""
    ) {
        setControllerData(prevState => ({
            ...prevState,
            similarcontroller: [...prevState.similarcontroller, { brandid: 0, controllerid: 0, comments: '', similarfingerspacing: 1 }]
        }));
    } else {
        // alert("Please complete all fields of the existing similar controller before adding a new one.");
    }
  };

  const handleChangeSimilarController = (index, field, value) => {
    const updatedList = [...controllerData.similarcontroller];
    if (field === 'brandid' || field === 'controllerid') {
      value = parseInt(value, 10);
    }
    updatedList[index][field] = value;
    setControllerData({ ...controllerData, similarcontroller: updatedList });
  };

  const renderControllerOptions2c = (brandid) => {
    const filteredControllers = controllers.filter(c => c.brandid === parseInt(brandid));
    return filteredControllers.map(controller => (
      <option key={controller.controllerid} value={controller.controllerid}>{controller.title}</option>
    ));
  };
  
  const handleAddSeller = () => {
    const lastSeller = controllerData.sellers[controllerData.sellers.length - 1];
    if (lastSeller && (lastSeller.name !== "" || lastSeller.link !== "")) {
        setControllerData({
            ...controllerData,
            sellers: [...controllerData.sellers, { name: "", link: "" }]
        });
    }
  };

  const handleAddImage = () => {
    const lastImage = controllerData.images[controllerData.images.length - 1];
    if (lastImage && lastImage.link !== "") {
        setControllerData({
            ...controllerData,
            images: [...controllerData.images, { link: "" }]
        });
    }
  };
  
  const handleBrandChange = (event) => {
    const brandId = event.target.value;
    setControllerData(prevState => ({
      ...prevState,
      brand: parseInt(brandId, 10)
  }));
  };
  
  const handleCopyToClipboard = () => {
    if (jsonData) {
      navigator.clipboard
        .writeText(JSON.stringify(jsonData, null, 2))
        .then(() => {
          alert("JSON data copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy JSON data:", err);
          alert("Failed to copy JSON data. Please try again.");
        });
    }
  };

  const handleSave = () => {
    const newData = { ...controllerData };

    newData.cost = newData.cost
    .map(cost => parseFloat(cost))
    .filter(cost => !isNaN(cost) && cost >= 0);

    newData.models = newData.models
      .filter(model => model.trim() !== "");

    newData.supportlinks = newData.supportlinks.filter(
      (supportlink) => 
        supportlink.link !== ""
       || supportlink.notes !== ""
    );
    newData.sellers = newData.sellers.filter(
      (seller) => seller.name !== "" || seller.link !== ""
    );
    newData.similarcontroller = newData.similarcontroller.filter(entry => 
      entry.controllerid !== 0 && 
      entry.brandid !== 0 && 
      entry.similarfingerspacing !== ""
    );
    newData.images = newData.images.filter((image) => image.link !== "");

    // Convert fields to integers
    const fieldsToInt = [
      "controllerid",
      "brand",
      "screen",
      "passthroughport",
      "customarttop",
      "customartbottom",
      "powerports",
      "powerporttype",
      "accessories",
      "adapters",
      "playlocation",
      "keycaps",
      "os",
      "stockswitches",
      "switchesupgrades",
      "controllerstyle",
      "size"
    ];
      
    fieldsToInt.forEach((field) => {
      if (Array.isArray(newData[field])) {
        newData[field] = newData[field].map((item) => parseInt(item, 10));
      } else {
        newData[field] = parseInt(newData[field], 10);
      }
    });

    const fieldsToBool = ["screen", "passthroughport", "customarttop", "customartbottom"];
    fieldsToBool.forEach((field) => {
      newData[field] = parseInt(newData[field], 10);
    });


    newData.similarcontroller = newData.similarcontroller.map((controller) => ({
      ...controller,
      similarfingerspacing: parseInt(controller.similarfingerspacing, 10)
    }));

    if (newData.cost) {
      newData.cost = newData.cost.map((cost) => Math.round(parseFloat(cost) * 100) / 100);
    }
        
    if (newData.buttoncountoptions) {
      newData.buttoncountoptions = newData.buttoncountoptions.map((option) =>
        parseInt(option, 10)
      );
    }
  
    const jsonData = JSON.stringify(newData, null, 2);
  
    // Save to clipboard
    navigator.clipboard
      .writeText(jsonData)
      .then(() => {
        console.log("Saved Data:", jsonData);
        // alert("Controller data copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy data:", err);
        alert("Failed to copy data to clipboard.");
      });

  };
  
  return (
    // <div>
    <div className="search-n-results">

      <form>

      <label>
        Brand:
        <br />

        <select
          style={{width: "100%"}}
          value={controllerData.brand}
          onChange={handleBrandChange}
        >
          <option value="">Select Brand</option>
          {brands.map(brand => (
              <option key={brand.brandid} value={brand.brandid}>
                  {brand.brandname}
              </option>
          ))}
        </select>
      </label>
      <br />

      <label>
          Controller ID:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.controllerid}
            onChange={(e) => handleInputChange("controllerid", e.target.value)}
          />
        </label>
        <br />

        <label>
          Title:
          <br />
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
          />
        </label>
        <br />

        <label>
          ArtPaperSize:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.artpapersize}
            onChange={(e) => handleInputChange("artpapersize", e.target.value)}
          />
        </label>
        <br />

        <label>
          Dimensions:
          <br />
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.dimensions}
            onChange={(e) => handleInputChange("dimensions", e.target.value)}
          />
        </label>
        <br />

        <label>
          Description:
          <br />
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </label>
        <br />

        <label>
          Cost (comma-separated for multiple entries):
          <br />          
          <input
            style={{width: "100%"}}
            type="text"
            value={costInput}
            onChange={(e) => setCostInput(e.target.value)}
            onBlur={handleCostBlur}  
          />
          </label>
          <br />

        <label>
          Models (comma-separated for multiple entries):
          <br />          
          <input
            style={{width: "100%"}}
            type="text"
            value={controllerData.models.join(", ")}
            onChange={(e) => handleInputChangeArray("models", e.target.value)}
          />
        </label>
        <br />
 
        <label>
          Comments:
          <br />          
          <input
           style={{width: "100%"}}
           type="text"
            value={controllerData.comments}
            onChange={(e) => handleInputChange("comments", e.target.value)}
          />
        </label>
        <br />

        <label>
          Powerports
          <br />          
          <input
            style={{width: "100%"}}
            type="number"
            value={controllerData.powerports}
            onChange={(e) => handleInputChange("powerports", e.target.value)}
          />
        </label>
        <br />

        <label>
          PowerPortType:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.powerporttype}
            onChange={(e) =>
              handleMultiSelectChange(
                "powerporttype",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">UBB-B to USB</option>
            <option value="2">USB-C to USB</option>
          </select>
        </label>
        <br />

        <label>
          Screen:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.screen}
           onChange={(e) => handleInputChange("screen", e.target.value)}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
          Passthroughport:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.passthroughport}
           onChange={(e) => handleInputChange("passthroughport", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
         CustomArtTop:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.customarttop}
           onChange={(e) => handleInputChange("customarttop", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
         customArtBottom:
          <br />
          <select
           style={{width: "100%"}}
           value={controllerData.customartbottom}
           onChange={(e) => handleInputChange("customartbottom", e.target.value)}         
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </label>
        <br />

        <label>
          Size:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.sizes || []}
            onChange={(e) => handleMultiSelectChange("size", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {sizeOptions.map((size, index) => (
              <option key={index} value={size.sizeid}>{size.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Controller Style:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.controllerstyles}
            onChange={(e) => handleMultiSelectChange("controllerstyles", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {controllerStyleOptions.map((style, index) => (
              <option key={index} value={style.controllerstyleid}>{style.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Keycaps:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.keycaps}
            onChange={(e) => handleMultiSelectChange("keycaps", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {keycapsOptions.map((keycap, index) => (
              <option key={index} value={keycap.keycapid}>{keycap.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          ButtonCountOptions:
          <br />
          <select
          style={{width: "100%"}}
            multiple
            value={controllerData.buttoncountoptions}
            onChange={(e) =>
              handleMultiSelectChange(
                "buttoncountoptions",
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </select>
        </label>
        <br />

        <label>
          Stock Switches:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.stockswitches}
            onChange={(e) => handleMultiSelectChange("stockswitches", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {switches.map(switchItem => (
              <option key={switchItem.switchid} value={switchItem.switchid}>{switchItem.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Switches Upgrades I Recommend:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.switchesupgrades}
            onChange={(e) => handleMultiSelectChange("switchesupgrades", Array.from(e.target.selectedOptions, option => option.value))}
          >
            {switches.map(switchItem => (
              <option key={switchItem.switchid} value={switchItem.switchid}>{switchItem.description}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          OS:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.os}
            onChange={(e) => handleMultiSelectChange(
              "os",
              Array.from(e.target.selectedOptions, option => option.value)
            )}
          >
            {osOptions.map(osItem => (
              <option key={osItem.osid} value={osItem.osid}>
                {osItem.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Play Location:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.playlocation}
            onChange={(e) => handleMultiSelectChange(
              "playlocation",
              Array.from(e.target.selectedOptions, option => option.value)
            )}
          >
            {playLocationOptions.map(location => (
              <option key={location.playlocationid} value={location.playlocationid}>
                {location.name}
              </option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Adapters:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.adapters}
            onChange={(e) => handleMultiSelectChange("adapters", Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {adaptersOptions.map((adapter, index) => (
              <option key={index} value={adapter.adaptersid}>{adapter.name}</option>
            ))}
          </select>
        </label>
        <br />

        <label>
          Accessories:
          <br />
          <select
            style={{width: "100%"}}
            multiple
            value={controllerData.accessories}
            onChange={(e) => handleMultiSelectChange("accessories", Array.from(e.target.selectedOptions, (option) => option.value))}
          >
            {accessoriesOptions.map((accessory, index) => (
              <option key={index} value={accessory.accessoriesid}>{accessory.name}</option>
            ))}
          </select>
        </label>
        <br />

        <br />
        <br />
        <label>
          Sellers:
          <br />
          {controllerData.sellers.map((seller, index) => (
            <div key={index}>
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Seller Name"
                value={seller.name}
                onChange={(e) => {
                  const updatedSellers = [...controllerData.sellers];
                  updatedSellers[index].name = e.target.value;
                  setControllerData({ ...controllerData, sellers: updatedSellers });
                }}
              />
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Seller Link"
                value={seller.link}
                onChange={(e) => {
                  const updatedSellers = [...controllerData.sellers];
                  updatedSellers[index].link = e.target.value;
                  setControllerData({ ...controllerData, sellers: updatedSellers });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddSeller}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}  
          >
            Add Seller
          </button>
        </label>
        <br />

        <br />
        <label>
          Images:
          <br />
          {controllerData.images.map((image, index) => (
            <div key={index}>
              <input
                style={{width: "100%"}}
                type="text"
                placeholder="Image Link"
                value={image.link}
                onChange={(e) => {
                  const updatedImages = [...controllerData.images];
                  updatedImages[index].link = e.target.value;
                  setControllerData({ ...controllerData, images: updatedImages });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddImage}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Image
          </button>
        </label>
        <br />


        <br />
        <label>
          Support Links:
          <br />
          {controllerData.supportlinks.map((supportlink, index) => (
            <div key={index}>
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Support Link URL"
                value={supportlink.link}
                onChange={(e) => {
                  const updatedSupportLinks = [...controllerData.supportlinks];
                  updatedSupportLinks[index].link = e.target.value;
                  setControllerData({ ...controllerData, supportlinks: updatedSupportLinks });
                }}
              />
              <input
                style={{width: "40%"}}
                type="text"
                placeholder="Notes"
                value={supportlink.notes}
                onChange={(e) => {
                  const updatedSupportLinks = [...controllerData.supportlinks];
                  updatedSupportLinks[index].notes = e.target.value;
                  setControllerData({ ...controllerData, supportlinks: updatedSupportLinks });
                }}
              />
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddSupportLink}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Support Link
          </button>
        </label>
        <br />


        <br />
        <label>
          Similar Controller:
          <br />
          
          {controllerData.similarcontroller.map((item, index) => (
            <div key={index}>
              <select
                value={item.brandid}
                onChange={e => handleChangeSimilarController(index, 'brandid', e.target.value)}
              >
                <option value="">Select Brand</option>
                {brands.map(brand => (
                  <option key={brand.brandid} value={brand.brandid}>{brand.brandname}</option>
                ))}
              </select>

              <select
                value={item.controllerid}
                onChange={e => handleChangeSimilarController(index, 'controllerid', e.target.value)}
                disabled={!item.brandid}
              >
                <option value="">Select Controller</option>
                {item.brandid && renderControllerOptions2c(item.brandid)}
              </select>

              <input
                type="text"
                placeholder="Comments"
                value={item.comments}
                onChange={e => handleChangeSimilarController(index, 'comments', e.target.value)}
              />

              <select
                value={item.similarfingerspacing}
                onChange={e => handleChangeSimilarController(index, 'similarfingerspacing', e.target.value)}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>

              <br />

            </div>
          ))}

          <button 
            type="button" 
            onClick={handleAddSimilarController}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Similar Controller
          </button>
        </label>


        <br />
        <br />
        <br />
        <button 
          type="button"
          onClick={handleSave}
          
          style={{
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Save
        </button>
      </form>


      <br />
      <hr />
      <br />
      
      
      <h2 style={{ marginTop: 0 }}>Loaded JSON Data:</h2>
      {jsonData && (
        <div
          style={{
            flex: "0 0 auto",
            backgroundColor: "#f8f9fa",
            padding: "10px",
            borderTop: "1px solid #ddd",
            overflowY: "auto",
            maxHeight: "200px",
          }}
        >
          <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
        </div>
      )} 
       



      {jsonData ? (
        <div>
          <h2>Loaded Data:</h2>
          <button
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleCopyToClipboard}
          >
            Copy Data List to Clipboard
          </button>
          <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {JSON.stringify(jsonData, null, 2)}
          </pre>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      ) : (
        <p>Loading data...</p>
      )}

    



    </div>
  );
};

export default AddControllerForm;
