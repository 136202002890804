import React, { useState, useEffect } from 'react';
import { filterControllers } from '../utils/filterControllers';
import ResultCard from './ResultCard';
import data from '../data/data-v3.json';

const ControllerStyleSelection = () => {
    const [controllerStyles, setControllerStyles] = useState([]);
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setControllerStyles(data.controllerstyles);
    }, []);

    const handleStyleChange = (e) => {
        const { options } = e.target;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(parseInt(options[i].value));
            }
        }
        setSelectedStyles(selected);
    };

    const handleFilter = () => {
        if (selectedStyles.length === 0) return;
        const filteredResults = filterControllers(selectedStyles, 'controllerstyles');
        setResults(filteredResults);
    };

    return (
        <div className="search-n-results">
            <h4 className="title">Controller Style Selection</h4>

            <div className="filter-container">
                {/* <label htmlFor="controller-style">Select Controller Styles:</label> */}
                <label>Select Controller Styles:</label>
                <br />

                <select
                    id="controller-style"
                    multiple
                    value={selectedStyles}
                    onChange={handleStyleChange}
                    style={{ width: '50%' }}
                > 
                    {controllerStyles.map((style) => (
                        <option key={style.controllerstyleid} value={style.controllerstyleid}>
                            {style.description}
                        </option>
                    ))}
                </select>
                <br />
                <button onClick={handleFilter} className="filter-button">Filter</button>
            </div>

            <div className="results-container">
                <h2>{results.length} Brands Found:</h2>
                {results.length > 0 ? (
                    results.map((result, index) => (
                        <ResultCard key={index} index={index + 1} brandData={result.brandData} filteredControllers={result.filteredControllers} />                        
                    ))
                ) : (
                    <p className="no-results">No results found. Please select styles.</p>
                )}
            </div>
        </div>
    );
};

export default ControllerStyleSelection;
