import data from '../data/data-v3.json';

const isControllerIdValid = (id) => {
    return data.brands.some(brand =>
        brand.controllers.some(controller => controller.controllerid === id)
    );
};

const getControllerDetailsById = (id) => {
    for (const brand of data.brands) {
        const controller = brand.controllers.find(controller => controller.controllerid === id);
        if (controller) {
            return {
                title: controller.title,
                description: controller.description,
                brand: brand.brandname
            };
        }
    }
    return null;
};

const findDescription = (ids, type) => {
    if (!data[type]) {
        console.error(`findDescription error: data[${type}] is undefined`);
        return ids.map(() => 'Description not found');
    }

    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item) {
            console.error(`findDescription error: No item found for id ${id} in type ${type}`);
        }
        return item ? item.description : 'Description not found';
    });
};

const findSwitchesDetails = (ids, type) => {
    const details = ids.map(id => {
        const item = data[type].find(item => item.switchid === parseInt(id));
        if (!item) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }

        const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
        const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

        return {
            description: item.description,
            notes: item.notes,
            styles: item.styles,
            link: link,
            seller: seller
        };
    });

    details.sort((a, b) => a.description.localeCompare(b.description));
    return details;
};

const findAccessoriesDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item.accessoriesid === parseInt(id));

        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findKeycapsDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

const findAdaptersDetails = (ids, type) => {
    return ids.map(id => {
        const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
        if (!item || !item.links || item.links.length === 0) {
            return { description: 'Description not found', link: '#', seller: 'N/A' };
        }
        return {
            description: item.description,
            link: item.links[0].link,
            seller: item.links[0].seller
        };
    });
};

export const filterSimilarControllers = (selectedIds, filterKey) => {
    // console.log("fSC - selectedIds", selectedIds);
    // console.log("fSC - filterKey", filterKey);

    const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

    return sortedBrands.map(brand => {
        // console.log("fSC - Brand:", brand.brandname);
        // brand.controllers.forEach(c => {
        //     console.log(`Controller: ${c.title}, Similar IDs: ${c.similarcontrollers}`);
        // });

        const filteredControllers = brand.controllers.filter(controller =>
            selectedIds.some(id => controller[filterKey] && controller[filterKey].includes(id))
        ).sort((a, b) => a.title.localeCompare(b.title));

        // console.log("fSC - filteredControllers", filteredControllers);

        if (filteredControllers.length > 0) {
            const enhancedControllers = filteredControllers.map(controller => {
                const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
                    isControllerIdValid(similarId)
                );

                const similarControllersDesc = validSimilarControllers.map(similarId =>
                    getControllerDetailsById(similarId)
                ).filter(details => details !== null);

                return {
                    ...controller,
                    brandName: brand.brandname,
                    controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
                    sizesDesc: findDescription(controller.sizes, 'sizes'),
                    playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
                    keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
                    accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
                    switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
                    switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
                    switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
                    adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
                    similarControllersDesc: similarControllersDesc
                };
            });

            return {
                brandData: brand,
                filteredControllers: enhancedControllers
            };
        }
        return null;
    }).filter(Boolean);
};





























// import data from '../data/data-v3.json';

// const isControllerIdValid = (id) => {
//     return data.brands.some(brand =>
//         brand.controllers.some(controller => controller.controllerid === id)
//     );
// };

// const getControllerDetailsById = (id) => {
//     for (const brand of data.brands) {
//         const controller = brand.controllers.find(controller => controller.controllerid === id);
//         if (controller) {
//             return {
//                 title: controller.title,
//                 description: controller.description,
//                 brand: brand.brandname
//                 // Include other details as needed
//             };
//         }
//     }
//     return null; // Return null or a default object if no controller is found
// };

// const findDescription = (ids, type) => {
//     // console.log("findDescription - ids:", ids, "type:", type);

//     // Check if the data[type] is defined
//     if (!data[type]) {
//         console.error(`findDescription error: data[${type}] is undefined`);
//         return ids.map(() => 'Description not found'); // Return 'Description not found' for each id as a fallback
//     }

//     return ids.map(id => {
//     // console.log("fSC - ids", ids)
//     // console.log("fSC - type", type)
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         // const item = data[type].find(item => item[type + 'id'] === parseInt(id));
//         if (!item) {
//             console.error(`findDescription error: No item found for id ${id} in type ${type}`);
//         }
//         return item ? item.description : 'Description not found';
//     });
// };

// const findSwitchesDetails = (ids, type) => {
//     const details = ids.map(id => {
//         const item = data[type].find(item => item.switchid === parseInt(id));
//         if (!item) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         // Handle cases where there might be no links provided
//         const link = item.links && item.links.length > 0 ? item.links[0].link : '#';
//         const seller = item.links && item.links.length > 0 ? item.links[0].seller : 'N/A';

//         return {
//             description: item.description,
//             notes: item.notes,
//             styles: item.styles,
//             link: link,
//             seller: seller
//         };
//     });

//     // Sort the details array by description alphabetically
//     details.sort((a, b) => a.description.localeCompare(b.description));
//     return details;
// };

// const findAccessoriesDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item.accessoriesid === parseInt(id));
//         // const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };

// const findKeycapsDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };

// const findAdaptersDetails = (ids, type) => {
//     return ids.map(id => {
//         const item = data[type].find(item => item[type.slice(0, -1) + 'id'] === parseInt(id));
//         if (!item || !item.links || item.links.length === 0) {
//             return { description: 'Description not found', link: '#', seller: 'N/A' };
//         }
//         return {
//             description: item.description,
//             link: item.links[0].link,
//             seller: item.links[0].seller
//         };
//     });
// };





// // export const filterSimilarControllers = (selectedIds, filterKey) => {
// //     console.log("fSC - selectedIds", selectedIds)
// //     console.log("fSC - filterKey", filterKey)

// //     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

// //     // return data.brands.map(brand => {
// //     return sortedBrands.map(brand => {
// //         // console.log("fSC - brand", brand)
// //         console.log("fSC - Brand:", brand.brandname);
// //         console.log("fSC - Checking controllers for IDs:", selectedIds);
// //         brand.controllers.forEach(c => {
// //             console.log(`Controller: ${c.title}, Similar IDs: ${c.similarcontrollers}`);
// //         });

// //         const filteredControllers = brand.controllers.filter(controller => 
// //             // selectedIds.some(id => controller[filterKey].includes(id.toString()))
// //             selectedIds.some(id => controller[filterKey].includes(id))
// //         ).sort((a, b) => a.title.localeCompare(b.title));  // Sorting controllers by title
// //         // );
    
// //         console.log("fSC - filteredControllers", filteredControllers)
// //         // console.log("fSC - controller", controller)

// //         if (filteredControllers.length > 0) {
// //         //     const enhancedControllers = filteredControllers.map(controller => {

// //             //  // Filter similarControllers to include only valid controller IDs
// //             //  const validSimilarControllers = controller.similarcontrollers.filter(similarId =>
// //             //     isControllerIdValid(similarId)
// //             // );
            
// //             const enhancedControllers = filteredControllers.map(controller => {
// //             // Ensure similarcontrollers is an array before filtering, default to empty array if undefined
// //             const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
// //                 isControllerIdValid(similarId)
// //             );

// //             const similarControllersDesc = validSimilarControllers.map(similarId => 
// //                 getControllerDetailsById(similarId)
// //             ).filter(details => details !== null);  // Filter out null values if any

// //             // const enhancedControllers = filteredControllers.map(controller => ({

// //             return {                
// //                 ...controller,
// //                 controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
// //                 sizesDesc: findDescription(controller.sizes, 'sizes'),
// //                 playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
// //                 keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
// //                 accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
                
// //                     switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
                
// //                 switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
// //                 switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),

// //                 adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),

// //                 // similarControllersDesc: validSimilarControllers
// //                 similarControllersDesc: similarControllersDesc

// //                 };
// //             });

// //             // console.log("fSC - enhancedControllers", enhancedControllers)
// //             // console.log("fSC - brand", brand)
// //             // console.log("fSC - filteredControllers", filteredControllers)


// //             return {
// //                 brandData: brand,
// //                 filteredControllers: enhancedControllers
// //             };
// //         }
// //         return null;
// //     }).filter(Boolean);
// // };



// // export const filterSimilarControllers = (selectedIds, filterKey) => {
// //     console.log("fSC - selectedIds", selectedIds);
// //     console.log("fSC - filterKey", filterKey);

// //     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

// //     return sortedBrands.map(brand => {
// //         // console.log("fSC - Brand:", brand.brandname);
// //         brand.controllers.forEach(c => {
// //             console.log(`Controller: ${c.title}, Similar IDs: ${c.similarcontrollers}`);
// //         });

// //         const filteredControllers = brand.controllers.filter(controller =>
// //             selectedIds.some(id => controller[filterKey] && controller[filterKey].includes(id))
// //         ).sort((a, b) => a.title.localeCompare(b.title));

// //         console.log("fSC - filteredControllers", filteredControllers);

// //         if (filteredControllers.length > 0) {
// //             const enhancedControllers = filteredControllers.map(controller => {
// //                 const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
// //                     isControllerIdValid(similarId)
// //                 );

// //                 const similarControllersDesc = validSimilarControllers.map(similarId =>
// //                     getControllerDetailsById(similarId)
// //                 ).filter(details => details !== null);

// //                 return {
// //                     ...controller,
// //                     brandName: brand.brandname,
// //                     controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
// //                     sizesDesc: findDescription(controller.sizes, 'sizes'),
// //                     playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
// //                     keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
// //                     accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
// //                     switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
// //                     switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
// //                     switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
// //                     adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
// //                     similarControllersDesc: similarControllersDesc
// //                 };
// //             });

// //             return {
// //                 brandData: brand,
// //                 filteredControllers: enhancedControllers
// //             };
// //         }
// //         return null;
// //     }).filter(Boolean);
// // };



// export const filterSimilarControllers = (selectedIds, filterKey) => {
//     console.log("fSC - selectedIds", selectedIds);
//     console.log("fSC - filterKey", filterKey);

//     const sortedBrands = data.brands.sort((a, b) => a.brandname.localeCompare(b.brandname));

//     return sortedBrands.map(brand => {
//         console.log("fSC - Brand:", brand.brandname);
//         brand.controllers.forEach(c => {
//             console.log(`Controller: ${c.title}, Similar IDs: ${c.similarcontrollers}`);
//         });

//         const filteredControllers = brand.controllers.filter(controller =>
//             selectedIds.some(id => controller[filterKey] && controller[filterKey].includes(id))
//         ).sort((a, b) => a.title.localeCompare(b.title));

//         console.log("fSC - filteredControllers", filteredControllers);

//         if (filteredControllers.length > 0) {
//             const enhancedControllers = filteredControllers.map(controller => {
//                 const validSimilarControllers = (controller.similarcontrollers || []).filter(similarId =>
//                     isControllerIdValid(similarId)
//                 );

//                 const similarControllersDesc = validSimilarControllers.map(similarId =>
//                     getControllerDetailsById(similarId)
//                 ).filter(details => details !== null);

//                 return {
//                     ...controller,
//                     brandName: brand.brandname,
//                     controllerstylesDesc: findDescription(controller.controllerstyles, 'controllerstyles'),
//                     sizesDesc: findDescription(controller.sizes, 'sizes'),
//                     playLocationsDesc: findDescription(controller.playlocations, 'playlocations'),
//                     keycapsDesc: findKeycapsDetails(controller.keycaps, 'keycaps'),
//                     accessoriesDesc: findAccessoriesDetails(controller.accessories, 'accessories'),
//                     switchesDesc: findSwitchesDetails(controller.stockswitches.concat(controller.switchesupgrades), 'switches'),
//                     switchesStockDesc: findSwitchesDetails(controller.stockswitches, 'switches'),
//                     switchesUpgradeDesc: findSwitchesDetails(controller.switchesupgrades, 'switches'),
//                     adaptersDesc: findAdaptersDetails(controller.adapters, 'adapters'),
//                     similarControllersDesc: similarControllersDesc
//                 };
//             });

//             return {
//                 brandData: brand,
//                 filteredControllers: enhancedControllers
//             };
//         }
//         return null;
//     }).filter(Boolean);
// };


