import React from 'react';
import AccessoriesSelection from '../components/AccessoriesSelection';
import '../pagestyles/AccessoriesSelectionPage.scss';
import Footer from '../components/Footer';

const AccessoriesSelectionPage = () => {
  return (
    <div className="selection-page">
      <AccessoriesSelection />
      <Footer />
    </div>
  );
};

export default AccessoriesSelectionPage;
